import useAuthStore from '@/stores/AuthStore'
import useConversationStore from '@/stores/ConversationStore'
import { Flex, Result, Spin } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Logout() {

  const navigate = useNavigate()

  const logout = useAuthStore(state => state.logout)
  const reset = useConversationStore((state) => state.reset);

  useEffect(() => {
    logout()
    reset();
    navigate('/p/dingding/login')
	}, [])

  return (
    <Flex justify='center' align='center' gap='middle' vertical>
      <Result
        status='success'
        title='退出成功'
        subTitle='正在跳转登陆页面'
        extra={[
          <Spin size='small' />
        ]}
      />
    </Flex>
  )
}

export default Logout
