import { loadScript } from '@/utils/commons';

export const loadPageSpy = (title) => {
  const PageSpySrc = [
    'https://page-spy.mycht.cn/page-spy/index.min.js',
    'https://page-spy.mycht.cn/plugin/data-harbor/index.min.js',
    'https://page-spy.mycht.cn/plugin/rrweb/index.min.js',
  ];
  Promise.all(PageSpySrc.map((src) => loadScript(src))).then(() => {
    // 注册插件
    PageSpy.registerPlugin(new DataHarborPlugin({ maximum: 2 * 1024 * 1024 }));
    // 实例化 PageSpy
    window.$pageSpy = new PageSpy({ api: 'page-spy.mycht.cn', project: 'Sales CRM', title: title, autoRender: false });
  });
};
