import { useEffect, useState } from 'react';
import { Layout, Spin, Button } from 'antd';
import { RightCircleOutlined, RightOutlined, ReloadOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
// import { useParams, useNavigate } from 'react-router-dom';
import MessagesHeader from './Conversations/Online/MessagesHeader';
import MessagesWrapper from './Conversations/Online/MessagesWrapper';
import InputComposer from './Conversations/Online/InputComposer';
import ConversationsList from './Conversations/Online/ConversationsList';
import CustomerProfile from './Conversations/Online/order/CustomerProfile';
// import { useAuthContext } from '@/stores/AuthContext';
// import useConversationStore from '@/stores/ConversationStore';

import './Conversations/Conversations.css';

const { Sider, Content, Header, Footer } = Layout;

/**
 *
 */
const ChatWindow = () => {

  const [collapsedLeft, setCollapsedLeft] = useState(false);
  const [collapsedRight, setCollapsedRight] = useState(false);

  return (
    <>
      <Layout hasSider className='h-screen chatwindow-wrapper' style={{ maxHeight: 'calc(100% - 198px)', height: 'calc(100% - 198px)' }}>
        <Sider
          width={300}
          theme={'light'}
          className='h-full overflow-y-auto h-parent'
          style={{ maxHeight: 'calc(100vh - 198px)', height: 'calc(100vh - 198px)' }}
          collapsible={true}
          breakpoint='xl'
          collapsedWidth={73}
          collapsed={collapsedLeft}
          onBreakpoint={(broken) => {
            setCollapsedLeft(broken)
            setCollapsedRight(broken)
          }}
          trigger={null}>
          <ConversationsList />
        </Sider>

        <Content style={{ maxHeight: 'calc(100vh - 198px)', height: 'calc(100vh - 198px)', minWidth: '360px' }}>
          <Layout className='h-full'>
            <Header className='px-1 ant-layout-sider-light ant-card h-auto flex justify-between gap-1 items-center'>
              <Button type='text' icon={collapsedLeft ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={() => setCollapsedLeft(!collapsedLeft)} className=' rounded-none rounded-l' />
              <MessagesHeader />
              {/* <Button type='text' icon={<ReloadOutlined />} onClick={() => setCollapsedRight(!collapsedRight)} className='' title='最新消息记录' /> */}
              <Button type='text' icon={collapsedRight ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />} onClick={() => setCollapsedRight(!collapsedRight)} className=' rounded-none rounded-r' />
            </Header>
            <Content className="flex-grow bg-whatsapp-bg relative" >
              <MessagesWrapper />
            </Content>
            <Footer className='ant-layout-sider-light p-0'>
              <InputComposer />
            </Footer>
          </Layout>
        </Content>

        <Sider
          width={300}
          theme={'light'}
          className=' overflow-y-auto'
          style={{ maxHeight: 'calc(100vh - 198px)', height: 'calc(100vh - 198px)' }}
          collapsible={true}
          breakpoint='xl'
          collapsedWidth={0}
          trigger={null}
          collapsed={collapsedRight}>
          <CustomerProfile />
        </Sider>
      </Layout>
    </>
  );
};

export default ChatWindow;
