import { createContext, useContext, useEffect, useState } from 'react';
import { App } from 'antd';
import { calcCacheSizes, clearAllCaches } from '@/utils/commons';

const ClearCache = (props) => {
  const { message } = App.useApp();

  // useEffect(() => {
  //   const cacheSizeInfo = calcCacheSizes();
  // }, []);
  const clearAllCachesA = async () => {
    await clearAllCaches(message.success(`清除成功🎉`));
  }
  return (
    <>
      <a onClick={clearAllCachesA}>
        清除缓存{/* &nbsp;{cacheSizeInfo.totalSize > 0 && <span>{formatBytes(cacheSizeInfo.totalSize)}</span>} */}
      </a>
    </>
  );
};
export default ClearCache;
