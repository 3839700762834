import { useState, useEffect } from 'react';
import { Form, Input, Modal } from 'antd';
import { isEmpty, pick } from '@/utils/commons';
import useConversationStore from '@/stores/ConversationStore';
import { phoneNumberToWAID } from '@/channel/whatsappUtils';
import { useConversationNewItem } from '@/hooks/useConversation';

export const ConversationItemForm = ({ initialValues, onFormInstanceReady }) => {
  const [currentConversation] = useConversationStore((state) => [state.currentConversation]);
  const fromCurrent = initialValues?.is_current_order ? pick(currentConversation, ['coli_sn', 'coli_id', 'opi_sn']) : {};
  const [form] = Form.useForm();
  const [formatPhone, setFormatPhone] = useState('');
  const [formatVisible, setFormatVisible] = useState(false);

  useEffect(() => {
    onFormInstanceReady(form);
    setFormatPhone(phoneNumberToWAID(initialValues.phone_number || ''));
  }, []);

  useEffect(() => {
    form.setFieldValue('wa_id', formatPhone);
    return () => {};
  }, [formatPhone]);

  const onValuesChange = (changeValues, allValues) => {
    if ('phone_number' in changeValues) {
      const _t = phoneNumberToWAID(changeValues.phone_number);
      setFormatPhone(_t);
      setFormatVisible(_t !== changeValues.phone_number);
    }
  };
  return (
    <Form layout='horizontal' form={form} name='form_in_modal' initialValues={{ ...fromCurrent, ...initialValues, wa_id: formatPhone }} onValuesChange={onValuesChange}>
      <Form.Item
        name={'phone_number'}
        label='WhatsApp号码'
        validateStatus={formatVisible ? 'warning' : undefined}
        help={formatVisible ? `WhatsApp格式: ${formatPhone}` : undefined}
        rules={[{ required: true, message: '请输入联系人手机号' }]}>
        <Input placeholder='请输入联系人手机号' />
      </Form.Item>
      {/* hidden */}
      <Form.Item hidden name={'wa_id'} dependencies={['phone_number']}>
        <Input />
      </Form.Item>
      {initialValues.is_current_order && (
        <>
          <Form.Item
            name={'coli_id'}
            label={'关联当前订单'}
            rules={[{ required: true, message: '关联的订单' }]}
            validateStatus='warning'
            help='请务必确认关联的订单是否正确'>
            <Input placeholder='请先关联订单' disabled={initialValues.is_current_order} />
          </Form.Item>
          <Form.Item name={'coli_sn'} label='订单SN' hidden={initialValues.is_current_order} rules={[{ required: true, message: '订单SN' }]}>
            <Input placeholder='订单SN' />
          </Form.Item>
        </>
      )}
      {!initialValues.is_current_order && (
        <Form.Item name={'name'} label='联系人名称' rules={[{ required: true, message: '请输入联系人名称' }]} className='mb-1'>
          <Input placeholder='请输入联系人名称' />
        </Form.Item>
      )}
      {/* <div className=' text-neutral-500 italic'>如果会话已存在, 将直接切换</div> */}
    </Form>
  );
};
/**
 * 新建会话弹窗
 * * 订单信息下的WhatsApp号码处点击: 关联此订单
 * * 消息记录中的号码点击: 不自动关联
 * * 消息记录中的联系人卡片点击: 不自动关联
 */
export const ConversationItemFormModal = ({ open, onCreate, onCancel, initialValues, }) => {
  const [formInstance, setFormInstance] = useState();
  const [newItemLoading, setNewItemLoading] = useState(false);

  const { newConversation } = useConversationNewItem();

  return (
    <Modal
      open={open}
      title='新建会话'
      okText='确认'
      // cancelText='Cancel'
      okButtonProps={{
        autoFocus: true,
      }}
      confirmLoading={newItemLoading}
      onCancel={() => {
        onCancel();
        formInstance?.resetFields();
      }}
      destroyOnClose
      onOk={async () => {
        try {
          const values = await formInstance?.validateFields();
          // formInstance?.resetFields();
          setNewItemLoading(true);
          if (initialValues.is_current_order !== true) newConversation(values.wa_id, values.name);
          if (typeof onCreate === 'function') {
            onCreate(values);
          }
          setNewItemLoading(false);
        } catch (error) {
          console.log('Failed:', error);
        }
      }}>
      <ConversationItemForm
        initialValues={initialValues}
        onFormInstanceReady={(instance) => {
          setFormInstance(instance);
        }}
      />
    </Modal>
  );
};
export default ConversationItemFormModal;
