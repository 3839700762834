import ErrorBoundary from '@/components/ErrorBoundary'
import useAuthStore from '@/stores/AuthStore'
import useConversationStore from '@/stores/ConversationStore'
import { useThemeContext } from '@/stores/ThemeContext'
import { App as AntApp, ConfigProvider, Empty, theme } from 'antd'
import zhLocale from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import { useEffect } from 'react'
import { Outlet, useHref, useNavigate } from 'react-router-dom'
import { appendRequestHeader } from '@/utils/request'
import { loadPageSpy } from '@/utils/pagespy'

import AppLogo from '@/assets/highlights_travel_300_300.png'
import '@/assets/App.css'
import 'react-chat-elements/dist/main.css'

function AuthApp() {

  const navigate = useNavigate()

  const { colorPrimary, borderRadius } = useThemeContext()
  const loginUser = useAuthStore(state => state.loginUser)

  const href = useHref()

  const [connectWebsocket, fetchInitialData, disconnectWebsocket ] = useConversationStore((state) => [
    state.connectWebsocket,
    state.fetchInitialData,
    state.disconnectWebsocket,
  ]);
  useEffect(() => {
    if (!("Notification" in window)) {
      // alert("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    if (loginUser.userId > 0) {
      appendRequestHeader('X-User-Id', loginUser.userId);
      loadPageSpy(loginUser.username);
      connectWebsocket(loginUser.userId);
      fetchInitialData(loginUser.userId);
    }
    return () => {
      disconnectWebsocket();
    };
  }, [])

  // 除了路由 /p...以外都需要登陆系统
  const needToLogin = (loginUser.userId === -1) && (href.indexOf('/p/') === -1)

  useEffect(() => {
    if (needToLogin) {
      navigate('/p/dingding/login?origin_url=' + href)
    }
	}, [href])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPrimary,
          borderRadius: borderRadius,
          fontFamily:
            "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Noto Color Emoji','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
        },
        algorithm: theme.defaultAlgorithm,
      }}
      locale={zhLocale}
      renderEmpty={() => <Empty description={false} />}>
      <AntApp>
        <ErrorBoundary>
          {needToLogin ? <>login...</> : <Outlet />}
          <dialog id='about-dialog' className='border-0'>
            <img className='logo' src={AppLogo} alt='logo' />
            <section className='about'>
              <h1>销售平台</h1>
              <h2>Sales CRM</h2>
              <p>Haina travel global sales CRM system</p>
            </section>
            <form className='actions flex gap-1' method='dialog'>
              <button value='cancel' className='px-4 py-2 rounded-full border-0'>Close</button>
              <button value='install' id='install-button' className='px-4 py-2 rounded-full border-0 border-transparent bg-indigo-500 text-white'>
                Install app
              </button>
            </form>
          </dialog>
        </ErrorBoundary>
      </AntApp>
    </ConfigProvider>
  );
}

export default AuthApp
