import { useEffect, useState } from 'react';
import { Button, Form, Input, Radio, Modal } from 'antd';
import { MergeCellsOutlined } from '@ant-design/icons';
import { fetchConversationMerge } from '@/actions/ConversationActions';
import useAuthStore from '@/stores/AuthStore';
import {PERM_MERGE_CONVERSATION} from '@/stores/AuthStore';

export const MergeToForm = ({ currentWAID, initialValues, onFormInstanceReady }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    onFormInstanceReady(form);
  }, []);

  const [mergeDir, setMergeDir] = useState(initialValues.direction);

  useEffect(() => {
    if (mergeDir === 'mergeto') {
      form.setFieldValue('phone_number_from', currentWAID);
      form.setFieldValue('phone_number_mergeto', '');
    } else {
      form.setFieldValue('phone_number_from', '');
      form.setFieldValue('phone_number_mergeto', currentWAID);
    }

    return () => {};
  }, [mergeDir]);

  const onValuesChange = (changeValues, allValues) => {
    if ('direction' in changeValues) {
      setMergeDir(changeValues.direction);
    }
  };

  return (
    <Form layout='horizontal' form={form} name='mergeto' initialValues={{ ...initialValues }} onValuesChange={onValuesChange}>
      {/* <Form.Item name={'direction'}>
        <Radio.Group
          options={[
            { label: 'Merge To', value: 'mergeto' },
            { label: 'Merge From', value: 'mergefrom' },
          ]}
          optionType='button'
          buttonStyle='solid'
        />
      </Form.Item> */}
      <Form.Item name={'phone_number_mergeto'} label='清空当前会话, 合并到' hidden={mergeDir === 'mergefrom'} rules={[{ required: true, message: '请输入目标会话的号码' }]}>
        <Input placeholder='请输入目标会话的号码' />
      </Form.Item>
      <Form.Item
        name={'phone_number_from'}
        label='清空会话'
        hidden={mergeDir === 'mergeto'}
        validateStatus={'warning'}
        help={`合并到当前: ${currentWAID}`}
        rules={[{ required: true, message: '请输入需要合并的会话的号码' }]}>
        <Input placeholder='请输入需要合并的会话的号码' />
      </Form.Item>
      {/* hidden */}
      <Form.Item hidden name={'opi_sn'}>
        <Input />
      </Form.Item>
      <Form.Item noStyle dependencies={['phone_number_from', 'phone_number_mergeto']}>
        {() => (
          <div>
            <div>🚩仅支持将发送的消息合并到接收会话</div>
            <span>{form.getFieldValue('phone_number_from')}</span>
            <span className='px-1'>➡</span>
            <span>{form.getFieldValue('phone_number_mergeto')}</span>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

const MergeConversationTo = ({ currentWAID, opi_sn, ...props }) => {
  const isPermitted = useAuthStore((state) => state.isPermitted);
  const [open, setOpen] = useState(false);
  const [formInstance, setFormInstance] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    await fetchConversationMerge({ ...values, opi_sn });
    setLoading(false);
    setOpen(false);
  };
  return (
    <>
      {isPermitted(PERM_MERGE_CONVERSATION) && <Button icon={<MergeCellsOutlined />} type='link' onClick={setOpen} />}
      <Modal
        open={open}
        title='合并会话'
        okText='确认'
        // cancelText='Cancel'
        okButtonProps={{
          autoFocus: true,
        }}
        confirmLoading={loading}
        onCancel={() => {
          // onCancel();
          setOpen(false);
          formInstance?.resetFields();
        }}
        destroyOnClose
        onOk={async () => {
          try {
            const values = await formInstance?.validateFields();
            await handleSubmit(values);
            formInstance?.resetFields();
          } catch (error) {
            console.log('Failed:', error);
          }
        }}>
        <MergeToForm
          currentWAID={currentWAID}
          initialValues={{ opi_sn, direction: 'mergeto' }}
          onFormInstanceReady={(instance) => {
            setFormInstance(instance);
          }}
        />
      </Modal>
    </>
  );
};

export default MergeConversationTo;
