import { useEffect } from 'react'
import {
  Row, Col, Space, Descriptions, Avatar, Tag, FloatButton, App
} from 'antd'
import { UserOutlined, BugOutlined } from '@ant-design/icons'
import useAuthStore from '@/stores/AuthStore'

function Profile() {

  const { message } = App.useApp()
  const loginUser = useAuthStore((state) => state.loginUser)
  const copyUserSession = useAuthStore((state) => state.copyUserSession)

  useEffect(() => {
    // 测试错误捕获：
    // throw new Error('💥 CABOOM 💥')  
  }, [])
  
  return (    
    <Row> 
      <Col span={12} offset={6}>
        <FloatButton icon={<BugOutlined />} onClick={() => {
          message.success('复制成功，请粘贴给开发人员，谢谢😀')
          copyUserSession()
        }} />
        <Descriptions title='个人资料' layout='vertical' column={2}>
          <Descriptions.Item label='名字'><Space size='middle'><Avatar src={loginUser.avatarUrl}>{loginUser.username.substring(1)}</Avatar>{loginUser.username}​</Space></Descriptions.Item>
          <Descriptions.Item label='HT 账号'>
          {loginUser.accountList?.map(a => { return (
            <Tag key={a.OPI_Code} icon={<UserOutlined />} bordered={false}>{a.OPI_Code}</Tag>
          )})}
          </Descriptions.Item>
          <Descriptions.Item label='手机'>{loginUser.mobile}</Descriptions.Item>
          <Descriptions.Item label='邮件'>{loginUser.email}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

export default Profile
