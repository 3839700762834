import { useEffect, useState } from 'react';
import { Image } from 'antd';
import useFormStore from '@/stores/FormStore';

const ImageAlbumPreview = (props) => {
  const [ImageAlbum,] = useFormStore((state) => [state.ImageAlbum, ]);
  const [ImagePreviewSrc, setImagePreviewSrc] = useFormStore((state) => [state.ImagePreviewSrc, state.setImagePreviewSrc]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewIndex, setPreviewIndex] = useState();
  const onPreviewClose = () => {
    setImagePreviewSrc('');
    setPreviewVisible(false);
  };
  useEffect(() => {
    if (ImagePreviewSrc) {
      setPreviewVisible(true);
      setPreviewIndex(ImageAlbum.findIndex((url) => url === ImagePreviewSrc));
    }

    return () => {};
  }, [ImagePreviewSrc]);

  return (
    <>
      <Image.PreviewGroup items={ImageAlbum} preview={{ current: previewIndex, visible: previewVisible, onClose: onPreviewClose, onChange: setPreviewIndex }} />
    </>
  );
};
export default ImageAlbumPreview;
