import { useRef, useState, useEffect } from 'react';
import useConversationStore from '@/stores/ConversationStore';
import { useShallow } from 'zustand/react/shallow';
import { Image, Modal, Button } from 'antd';
import MessagesList from './MessagesList';
import { fetchCleanUnreadMsgCount, fetchMessages, MESSAGE_PAGE_SIZE } from '@/actions/ConversationActions';
import useAuthStore from '@/stores/AuthStore';
import { useVisibilityState } from '@/hooks/useVisibilityState';
import ConversationNewItem from './ConversationsNewItem';

const MessagesWrapper = ({ updateRead = true, forceGetMessages }) => {
  const userId = useAuthStore((state) => state.loginUser.userId);

  const [currentConversation, updateCurrentConversation, setCurrentConversation] = useConversationStore((state) => [state.currentConversation, state.updateCurrentConversation, state.setCurrentConversation]);
  const conversationsList = useConversationStore((state) => state.conversationsList);
  const activeMessages = useConversationStore(useShallow((state) => (state.currentConversation.sn && state.activeConversations[state.currentConversation.sn] ? state.activeConversations[state.currentConversation.sn]: [])));
  const addToConversationList = useConversationStore((state) => state.addToConversationList);

  const receivedMessageList = useConversationStore((state) => state.receivedMessageList);
  const setMsgLoading = useConversationStore((state) => state.setMsgLoading);

  const refreshTotalNotify = useConversationStore(useShallow((state) => state.refreshTotalNotify));
  const isVisible = useVisibilityState();

  const [longList, setLongList] = useState([]);
  const [longListLoading, setLongListLoading] = useState(false);
  const [shouldScrollBottom, setShouldScrollBottom] = useState(true);
  useEffect(() => {
    if (currentConversation.sn && (activeMessages.length < 20 || forceGetMessages !== undefined)) {
      getFirstPageMessages(currentConversation);
    }
    setShouldScrollBottom(true);
    return () => {};
  }, [currentConversation.sn]);

  useEffect(() => {
    setLongList(activeMessages);
    const thisLastTime = activeMessages.length > 0 ? activeMessages[0].orgmsgtime : '';
    const loadNextPage = !(activeMessages.length === 0 || activeMessages.length < MESSAGE_PAGE_SIZE);
    updateCurrentConversation({ lasttime: thisLastTime, loadNextPage });
    return () => {};
  }, [activeMessages]);

  useEffect(() => {
    if (updateRead === true && isVisible && currentConversation.opi_sn && currentConversation.whatsapp_phone_number && activeMessages.length > 0) {
      fetchCleanUnreadMsgCount({ opisn: currentConversation.opi_sn, whatsappid: currentConversation.whatsapp_phone_number });
      refreshTotalNotify();
      updateCurrentConversation({ unread_msg_count: 0 });
    }
    return () => {};
  }, [activeMessages.length, isVisible]);


  const getFirstPageMessages = async (item) => {
    setMsgLoading(true);
    const data = await fetchMessages({ opisn: forceGetMessages ? (currentConversation.opi_sn || '') : userId, whatsappid: item.whatsapp_phone_number, lasttime: '' });
    setMsgLoading(false);
    receivedMessageList(item.sn, data);
    const thisLastTime = data.length > 0 ? data[0].orgmsgtime : '';
    const loadNextPage = !(data.length === 0 || data.length < MESSAGE_PAGE_SIZE);
    updateCurrentConversation({ lasttime: thisLastTime, loadNextPage });
  };

  const getMoreMessages = async () => {
    setShouldScrollBottom(false);
    setLongListLoading(true);
    const data = await fetchMessages({ opisn: currentConversation.opi_sn, whatsappid: currentConversation.whatsapp_phone_number, lasttime: currentConversation?.lasttime || '' });
    setLongListLoading(false);
    setLongList(prevValue => data.concat(prevValue));
    const thisLastTime = data.length > 0 ? data[0].orgmsgtime : '';
    const loadNextPage = !(data.length === 0 || data.length < MESSAGE_PAGE_SIZE);
    updateCurrentConversation({ lasttime: thisLastTime, loadNextPage });
    return data.length;
  };

  const reference = useRef(null);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewSrc, setPreviewSrc] = useState();
  const onPreviewClose = () => {
    setPreviewSrc('');
    setPreviewVisible(false);
  };
  const handlePreview = (msg) => {
    switch (msg.whatsapp_msg_type) {
      case 'image':
        setPreviewVisible(true);
        setPreviewSrc(msg.data.uri);
        return false;

      case 'document':
        window.open(msg.data.link || msg.data.uri, '_blank', 'noopener,noreferrer');
        return false;

      default:
        return false;
    }
  };

  const [contactsModalVisible, setContactsModalVisible] = useState(false);
  const [contactListData, setContactListData] = useState([]);

  const [newChatModalVisible, setNewChatModalVisible] = useState(false);
  const [newChatFormValues, setNewChatFormValues] = useState({});

  const handleContactItemClick = (contact) => {
    setNewChatFormValues(prev => ({...prev, phone_number: contact.wa_id, name: contact.name }));
    setNewChatModalVisible(true);
    return ;
  }
  const handleContactListClick = (data) => {
    setContactListData(data);
    setContactsModalVisible(true);
  }
  const handleContactClick = (data) => {
    return data.length > 1 ? handleContactListClick(data) : handleContactItemClick(data[0]);
  }
  return (
    <>
      <MessagesList messages={longList} dataSourceLen={longList.length} {...{
        reference, shouldScrollBottom,
        handlePreview, handleContactClick,
        setNewChatModalVisible, setNewChatFormValues,
        longListLoading, setLongListLoading, getMoreMessages, loadNextPage: currentConversation?.loadNextPage ?? true
        }}
      />
      <Image width={0} height={0} src={null} preview={{ visible: previewVisible, src: previewSrc, onClose: onPreviewClose, fallback: 'https://hiana-crm.oss-accelerate.aliyuncs.com/WAMedia/afe412d4-3acf-4e79-a623-048aeb4d696a.png' }} />
      <Modal title="联系人" closable open={contactsModalVisible} onOk={handleContactItemClick} onCancel={() => setContactsModalVisible(false)} footer={null} >
        {contactListData.map((contact) => (
          <Button onClick={() => handleContactItemClick(contact)} type='link' key={contact.id}>{contact.name}: <span>{contact.wa_id}</span></Button>
        ))}
      </Modal>
      <ConversationNewItem
        initialValues={{ ...newChatFormValues, is_current_order: false }}
        open={newChatModalVisible}
        onCreate={() => { setNewChatModalVisible(false); setContactsModalVisible(false);}}
        onCancel={() => setNewChatModalVisible(false)}
        />
    </>
  );
};
export default MessagesWrapper;
