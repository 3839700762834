import { useState } from 'react';
import { App, Modal, Button, Table } from 'antd';
import { isEmpty, cloneDeep } from '@/utils/commons';
import { fetchJSON } from '@/utils/request';
import AdvanceSearchForm from './../../orders/AdvanceSearchForm';
import { API_HOST } from '@/config';
import dayjs from 'dayjs';

const fetchOrderList = async (params) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/getdvancedwlorder`, params);
  return errcode !== 0 ? [] : result;
};

const fetchBindOrder = async (params) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/bound_order`, params);
  return errcode === 0 ? true : false;
  // return errcode !== 0 ? {} : result;
};

export const ConversationBindFormModal = ({ mobile, currentConversationID, userId, onBoundSuccess }) => {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false); // bind loading
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const { notification, message } = App.useApp();
  const onSearchOrder = async (values) => {
    const copyObject = cloneDeep(values);
    delete copyObject.type;
    const allEmpty = Object.values(copyObject).every((val) => {
      return val === null || val === '' || val === undefined;
    });
    if (allEmpty) {
      notification.warning({
        message: '温馨提示',
        description: '请输入至少一个条件',
        placement: 'top',
        duration: 60,
      });
      return false;
    }
    values.opisn = userId;
    setLoading(false);
    setSearchLoading(true);
    setSearchResult([]);
    const result = await fetchOrderList(values);
    setSearchResult(result);
    setSearchLoading(false);
  };
  const handleBindOrder = async (coli_sn) => {
    setLoading(true);
    const success = await fetchBindOrder({ coli_sn, conversationid: currentConversationID });
    setLoading(false);
    success ? message.success('绑定成功') : message.error('绑定失败');
    setOpen(false);
    if (typeof onBoundSuccess === 'function') {
      onBoundSuccess(coli_sn);
    }
  };
  const paginationProps = {
    showQuickJumper: true,
    showLessItems: true,
    showSizeChanger: true,
    showTotal: (total) => {
      return `总数：${total}`;
    },
  };
  const searchResultColumns = [
    {
      title: '订单号',
      key: 'COLI_ID',
      dataIndex: 'COLI_ID',
      width: 222,
    },
    {
      title: '客人姓名',
      key: 'coli_guest',
      dataIndex: 'coli_guest',
      render: (text, record) => {
        let regularText = '';
        if (record.buytime > 0) regularText = '(R' + record.buytime + ')';
        return text + regularText;
      },
    },
    {
      title: '出发日期',
      key: 'COLI_OrderStartDate',
      dataIndex: 'COLI_OrderStartDate',
      width: 120,
      hidden: false,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => {
        const datejsA = isEmpty(a.COLI_OrderStartDate) ? 0 : new dayjs(a.COLI_OrderStartDate).valueOf();
        const datejsB = isEmpty(b.COLI_OrderStartDate) ? 0 : new dayjs(b.COLI_OrderStartDate).valueOf();
        return datejsA - datejsB;
      },
    },
    {
      title: '附加信息',
      ellipsis: true,
      key: 'COLI_Introduction',
      dataIndex: 'COLI_Introduction',
    },
    {
      title: '',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <Button type={'text'} className='text-primary' onClick={() => handleBindOrder(record.COLI_SN)}>
          关联此订单
        </Button>
      ),
    },
  ];
  return (
    <>
      {currentConversationID && <Button type='primary' onClick={() => setOpen(true)} >
        现在关联
      </Button>}
      <Modal
        width={mobile === undefined ? '95%' : '100%'}
        open={open}
        title={'关联订单'}
        footer={false}
        onCancel={() => {
          setOpen(false);
        }}
        destroyOnClose>
        <AdvanceSearchForm onSubmit={onSearchOrder} loading={searchLoading} />
        <Table
          key={'advanceOrderTable'}
          loading={loading}
          dataSource={searchResult}
          columns={searchResultColumns}
          pagination={searchResult.length <= 10 ? false : paginationProps}
        />
      </Modal>
    </>
  );
};
export default ConversationBindFormModal;
