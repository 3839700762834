import { LinkOutlined } from '@ant-design/icons'
import { Button, Flex, List, Popover } from 'antd'
import { useState } from 'react'

const QuotesHistory = ((props) => {

  const [open, setOpen] = useState(false)

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const handleCopyClick = (url) => {    
    navigator.clipboard.writeText(url)
    setOpen(false)
  }

  return (
    <>
      <Popover
        content={
          <List
            className='w-96 h-4/6 overflow-y-auto text-slate-900'
            itemLayout='horizontal'
            pagination={{
              pageSize: 5,
              showLessItems: true,
              showTotal: (total) => { return `总数：${total}` }
            }}
            dataSource={props.dataSource}
            renderItem={(item, index) => (
              <List.Item className='' key={item.letterid} >
                <List.Item.Meta
                  className='text-neutral-800'
                  title={<a target='_blank' href={item.letterurl}><LinkOutlined />&nbsp;{item.lettertitle}</a>}
                  description={
                    <Flex justify='space-between'>
                      <span>{item.letterdate}</span>
                      <Button onClick={() => {
                        handleCopyClick(item.letterurl)
                      }} size={'small'} type='link' key={'send'}>
                        复制
                      </Button>
                    </Flex>
                  }
                />
              </List.Item>
            )}
          />
        }
        title='📜报价信历史'
        trigger='click'
        placement={'left'}
        open={open}
        onOpenChange={handleOpenChange}>
        <Button size={'small'}>报价历史</Button>
      </Popover>
    </>
  )
})

export default QuotesHistory
