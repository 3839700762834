import { useEffect } from 'react';
import '@/assets/App.css';
import AppLogo from '@/assets/highlights_travel_300_300.png';
import { useThemeContext } from '@/stores/ThemeContext';
import useAuthStore from '@/stores/AuthStore';
import { Layout, Typography, theme, Space, Avatar, Dropdown, Flex } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { NavLink, Outlet, Link } from 'react-router-dom';
import ReloadPrompt from './ReloadPrompt';
import ClearCache from './ClearCache';
import PageSpy from './PageSpy';

import { BUILD_VERSION } from '@/config';

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

function MobileApp() {
  const { colorPrimary, borderRadius } = useThemeContext();
  const loginUser = useAuthStore((state) => state.loginUser);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const handleLoad = () => {
      const isPWAInstalled = window.matchMedia('(display-mode: window-controls-overlay)').matches || window.matchMedia('(display-mode: standalone)').matches;
      const isStandalone = navigator.standalone || window.navigator.standalone;

      if (isPWAInstalled || isStandalone) {
        document.getElementById('install-button').disabled = true;
      } else {
        document.getElementById('about-dialog').showModal();
        window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();

          document.getElementById('about-dialog').addEventListener('close', () => {
            if (document.getElementById('about-dialog').returnValue === 'install') {
              e.prompt();
            }
          });
        });
      }
    };

    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  }, []);

  return (
    <Layout>
      <Header className='header px-2' style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: 'white' }}>
        <Flex justify={'space-between'}>
          <NavLink to='/'>
            <img src={AppLogo} className='logo' alt='App logo' />
            {!('Notification' in window) && <span>🔕</span>}
          </NavLink>
          <ReloadPrompt />
          <Dropdown
            menu={{
              items: [
                { label: <ReloadPrompt force />, key: 'reload' },
                { type: 'divider' },
                { label: <ClearCache />, key: 'clearcache' },
                { type: 'divider' },
                { label: <Link to='/p/dingding/logout'>退出</Link>, key: '3' },
                { type: 'divider' },
                { label: <>v{BUILD_VERSION}</>, key: 'BUILD_VERSION' },
                { type: 'divider' },
                { label: <PageSpy />, key: 'pagespy' },
              ],
            }}
            trigger={['click']}>
            <a onClick={(e) => e.preventDefault()} style={{ color: colorPrimary }}>
              <Space>
                <Avatar src={loginUser.avatarUrl}>{loginUser?.username?.substring(1)}</Avatar>
                {loginUser.username}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Flex>
        {/* <Row gutter={{ md: 24 }} align='middle'>
          <Col flex="auto" style={{ color: "white", marginBottom: "0", display: "flex", justifyContent: "center" }}>
          </Col>
        </Row> */}
      </Header>
      <Layout>
        <Content
          style={{
            padding: 0,
            margin: 0,
            minHeight: 200,
            background: colorBgContainer,
          }}>
          <Outlet />
        </Content>
      </Layout>
      {/* <Footer>桂林海纳国际旅行社有限公司</Footer> */}
    </Layout>
  );
}

export default MobileApp;
