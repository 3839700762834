import useAuthStore from '@/stores/AuthStore'
import { isNotEmpty } from '@/utils/commons'
import { Flex, Result, Spin } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// 钉钉扫码开发文档：https://open.dingtalk.com/document/orgapp/obtain-identity-credentials#title-4up-u8w-5ug
// OAuth 登录授权回调
// https://login.dingtalk.com/oauth2/auth?redirect_uri=https%3A%2F%2Fsales.mycht.cn%2Fp%2Fdingding%2Fcallback&response_type=code&client_id=dingwgdx6emlxr3fcrg8&scope=openid&state=global-saels&prompt=consent
function Callback() {

  const navigate = useNavigate()

  const login = useAuthStore((state) => state.login)
  const loginStatus = useAuthStore((state) => state.loginStatus)
  const urlSearch = new URLSearchParams(location.search)

  const authCode = urlSearch.get('authCode')
  const state = urlSearch.get('state')
  const error = urlSearch.get('error')
  const originUrl = urlSearch.get('origin_url')

  useEffect (() => {
    if (isNotEmpty(authCode) && state === 'global-saels') {
      login(authCode)
    } else {
      console.error('error: ' + error)
    }
  }, [])

  if (loginStatus === 200) {
    return (
      <Flex justify='center' align='center' gap='middle' vertical>
        <Result
          status='success'
          title='扫码成功'
          subTitle='正在获取你的权限'
          extra={[
            <Spin size='small' />
          ]}
        />
      </Flex>
    )
  } else if (loginStatus === 302) {
    const isMobileApp = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) !== null

    if (isNotEmpty(originUrl)) {
      navigate(originUrl)
    } else {
      if (isMobileApp) {
        navigate('/m/conversation', { replace: true })
      } else {
        navigate('/', { replace: true })
      }
    }
  } else if (loginStatus === 403) {
    return (
      <Flex justify='center' align='center' gap='middle' vertical>
        <Result
          status='403'
          title='403'
          subTitle='你没有绑定钉钉账号，无法登陆。'
        />
      </Flex>
    )
  } else {
    return (
      <Flex justify='center' align='center' gap='middle' vertical>
        <Result
          status='success'
          title='登陆成功'
          subTitle='正在获取你的权限'
          extra={[
            <Spin size='small' />
          ]}
        />
      </Flex>
    )
  }
}

export default Callback