import { create } from 'zustand'
import { fetchJSON, postForm } from '@/utils/request'
import { API_HOST } from '@/config'
import { isNotEmpty, prepareUrl } from '@/utils/commons'

export const useOrderStore = create((set, get) => ({

  orderList: [],
  orderDetail: {},
  customerDetail: {},
  lastQuotation: {},
  quotationList: [],

  fetchOrderList: async (formValues, loginUser) => {
    let fetchOrderUrl = `${API_HOST}/getwlorder?opisn=${loginUser.userIdStr}&otype=${formValues.type}`
    const params = {};

    if (formValues.type === 'advance') {
      fetchOrderUrl = `${API_HOST}/getdvancedwlorder?opisn=${loginUser.userIdStr}`;
      const { type, ...formParams } = formValues;
      Object.assign(params, formParams)
    }

    return fetchJSON(fetchOrderUrl, params)
      .then(json => {
        if (json.errcode === 0) {
          set(() => ({
            orderList: json.result.map((order) => { return { ...order, key: order.COLI_ID } }),
          }))
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })

  },

  fetchOrderDetail: (colisn) => {
    return fetchJSON(`${API_HOST}/getorderinfo`, { colisn })
      .then(json => {
        if (json.errcode === 0 && json.result.length > 0) {
          const orderResult = json.result[0]
          set(() => ({
            orderDetail: orderResult,
            customerDetail: orderResult.contact.length > 0 ? orderResult.contact[0] : {},
            lastQuotation: orderResult.quotes.length > 0 ? orderResult.quotes[0] : {},
            quotationList: orderResult.quotes,
          }))
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })

  },


  appendOrderComment: async (opi_sn, coli_sn, comment) => {
    const { fetchOrderDetail } = get()
    const formData = new FormData()
    formData.append('opi_sn', opi_sn)
    formData.append('coli_sn', coli_sn)
    formData.append('remark', comment)

    return postForm(`${API_HOST}/remark_order`, formData)
      .then(json => {
        if (json.errcode === 0) {
          return fetchOrderDetail(coli_sn)
        } else {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

  setOrderPropValue: async (colisn, propName, value) => {

    if (propName === 'orderlabel') {
      set((state) => ({
        orderDetail: {
          ...state.orderDetail,
          tags: value
        }
      }))
    }

    if (propName === 'orderstatus') {
      set((state) => ({
        orderDetail: {
          ...state.orderDetail,
          states: value
        }
      }))
    }

    return fetchJSON(`${API_HOST}/setorderstatus`, { colisn, stype: propName, svalue: value })
      .then(json => {
        if (json.errcode > 0) {
          throw new Error(json?.errmsg + ': ' + json.errcode)
        }
      })
  },

}))

export const OrderLabelDefaultOptions = [
  { value: 240003, label: '重点' },
  { value: 240002, label: '次重点' },
  { value: 240001, label: '一般' }
]

export const OrderStatusDefaultOptions = [
  { value: 1, label: '新订单' },
  { value: 2, label: '报价中' },
  { value: 3, label: '以后联系' },
  { value: 4, label: '等待付订金' },
  { value: 5, label: '成行' },
  { value: 6, label: '丢失' },
  { value: 7, label: '取消' },
  { value: 8, label: '未报价' }
]

export const RemindStateDefaultOptions = [
  { value: '1', label: '一催' },
  { value: '2', label: '二催' },
  { value: '3', label: '三催' }
]
