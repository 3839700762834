import '@/assets/App.css'
import AppLogo from '@/assets/highlights_travel_300_300.png'
import { useThemeContext } from '@/stores/ThemeContext'
import { App as AntApp, Col, ConfigProvider, Empty, Layout, Row, Typography, theme } from 'antd'
import { NavLink, Outlet } from 'react-router-dom'
const { Header, Footer, Content } = Layout
const { Title } = Typography

function Standlone() {

  const {colorPrimary, borderRadius} = useThemeContext()

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  function renderLayout() {
    return (
      <Layout>
        <Header className='header' style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: 'white' }}>
          <Row gutter={{ md: 24 }} align='middle'>
            <Col flex="auto" style={{ color: "white", marginBottom: "0", display: "flex", justifyContent: "center" }}>
              <NavLink to='/'>
                <img src={AppLogo} className='logo' alt='App logo' />
              </NavLink>
              <Title level={3}>
              销售平台
              </Title>
            </Col>
          </Row>
        </Header>
        <Layout>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}>
            <Outlet />
          </Content>
        </Layout>
        <Footer>桂林海纳国际旅行社有限公司</Footer>
      </Layout>
    )
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorPrimary,
          borderRadius: borderRadius
        },
        algorithm: theme.defaultAlgorithm,
      }}
      renderEmpty={() => <Empty description={false} />}
    >
      <AntApp>
        {renderLayout()}
      </AntApp>
    </ConfigProvider>
  )
}

export default Standlone
