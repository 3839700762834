import useConversationStore from '@/stores/ConversationStore';
import useAuthStore from '@/stores/AuthStore'
import { Flex, Typography, Avatar, Alert, Button, Tooltip, Spin } from 'antd';
import { ReloadOutlined, ApiOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import ExpireTimeClock from './ExpireTimeClock';

const MessagesHeader = () => {
  const userId = useAuthStore(state => state.loginUser.userId);
  const websocketOpened = useConversationStore(state => state.websocketOpened);
  const websocketRetrying = useConversationStore(state => state.websocketRetrying);
  const websocketRetrytimes = useConversationStore(state => state.websocketRetrytimes);
  const currentConversation = useConversationStore(state => state.currentConversation);
  const connectWebsocket = useConversationStore(state => state.connectWebsocket);
  const msgListLoading = useConversationStore((state) => state.msgListLoading);

  return (
    <>
      {websocketOpened === false && (
        <Alert
          type='error'
          message='断开连接'
          banner
          action={
            websocketRetrytimes === -1 ? (
              <Tooltip key={'connect'} title='点击重试'>
                <Button key={'connect'} type='text' icon={<ApiOutlined />} onClick={() => connectWebsocket(userId)} className=' text-blue-500' />
              </Tooltip>
            ) : null
          }
        />
      )}
      {websocketRetrying && websocketRetrytimes > 0 && <Alert type={'warning'} message={`正在重连, ${websocketRetrytimes}次...`} banner icon={<LoadingOutlined />} />}
      <Flex gap={16} className='p-1 flex-auto'>
        {/* {currentConversation.customer_name && <Avatar src={`https://api.dicebear.com/7.x/avataaars/svg?seed=${currentConversation.customer_name}`} />} */}
        <Flex flex={'1'} justify='space-between'>
          <Flex vertical={false} gap={12} justify='space-between'>
            {(currentConversation.coli_sn || currentConversation.sn) ? (
              <>
                <Typography.Text strong>{currentConversation.customer_name}</Typography.Text>
                {currentConversation.whatsapp_phone_number ? (
                  <Typography.Text>{currentConversation.whatsapp_phone_number}</Typography.Text>
                ) : (
                  <Typography.Text strong type='danger'>
                    没有WhatsApp号码
                  </Typography.Text>
                )}
              </>
            ) : (
              <Typography.Text strong className='text-primary'>
                请选择会话
              </Typography.Text>
            )}
            <Spin spinning={msgListLoading} />
          </Flex>
          <Flex vertical={true} justify='space-between'>
            <Typography.Text><ExpireTimeClock expireTime={currentConversation.conversation_expiretime} /></Typography.Text>
            {/* <Typography.Text>{customerDateTime}</Typography.Text> */}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default MessagesHeader;
