import useConversationStore from '@/stores/ConversationStore';
import { fetchOrderConversationsList, postNewConversationItem } from '@/actions/ConversationActions';
import { isEmpty } from '@/utils/commons';
export function useConversationNewItem() {
  const [currentConversation, setCurrentConversation] = useConversationStore((state) => [
    state.currentConversation,
    state.setCurrentConversation,
  ]);
  const conversationsList = useConversationStore((state) => state.conversationsList);
  const addToConversationList = useConversationStore((state) => state.addToConversationList);
  /**
   * 打开订单的会话, 不存在自动新增
   */
  const openOrderContactConversation = async (whatsappID) => {
    const { coli_sn, opi_sn } = currentConversation;
    let findCurrentOrderChats = conversationsList.filter(
      (item) => `${item.coli_sn}` === `${coli_sn}` && `${item.opi_sn}` === `${opi_sn}` && `${item.whatsapp_phone_number}` === `${whatsappID}`
    );
    let findCurrentIndex = isEmpty(findCurrentOrderChats) ? -1 : 0; // findCurrentOrderChats.length-1;
    let findCurrent = findCurrentOrderChats[findCurrentIndex];
    if (findCurrentIndex !== -1) {
      addToConversationList(findCurrentOrderChats);
    } else if (!isEmpty(whatsappID)) {
      const data = await fetchOrderConversationsList({ opisn: opi_sn, colisn: coli_sn, whatsappid: whatsappID });
      if (!isEmpty(data)) {
        addToConversationList(data);
        findCurrentIndex = data.findIndex((item) => `${item.coli_sn}` === `${coli_sn}` && `${item.opi_sn}` === `${opi_sn}` && `${item.whatsapp_phone_number}` === `${whatsappID}`);
        findCurrentIndex = findCurrentIndex >= 0 ? findCurrentIndex : 0;
        findCurrent = data[findCurrentIndex];
      }
    }
    if (findCurrentIndex >= 0) {
      setCurrentConversation(findCurrent);
    }
    return false;
  };

  /**
   * 创建新会话
   * whatsappID, whatsappName
   */
  const newConversation = async (whatsappID, whatsappName = '') => {
    const { opi_sn } = currentConversation;
    const newChat = { phone_number: whatsappID, remark_name: whatsappName };
    const createdNew = await postNewConversationItem({ ...newChat, opi_sn: opi_sn });
    addToConversationList([createdNew]);
    setCurrentConversation(createdNew);
  };

  return { openOrderContactConversation, newConversation };
}
