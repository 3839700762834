import { LinkOutlined, MailOutlined, PhoneOutlined, UserOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { App, Button, Card, Empty, Flex, Select, Spin, Typography, Divider, Modal } from "antd";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { copy, isEmpty } from "@/utils/commons";
import { Conditional } from "@/components/Conditional";
import useConversationStore from "@/stores/ConversationStore";
import { useOrderStore, OrderLabelDefaultOptions, OrderStatusDefaultOptions } from "@/stores/OrderStore";
import useAuthStore from "@/stores/AuthStore";
import QuotesHistory from "./QuotesHistory";
import ConversationBind from "./../ConversationBind";
import ConversationsNewItem from "./../ConversationsNewItem";
import { useConversationNewItem } from "@/hooks/useConversation";

const CustomerProfile = () => {
	const { notification, message } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const orderCommentRef = useRef(null);
	const currentOrder = useConversationStore(state => state.currentConversation?.coli_sn || "");
	const currentConversationID = useConversationStore(state => state.currentConversation?.sn || "");
	const [updateCurrentConversation] = useConversationStore(state => [state.updateCurrentConversation]);
	const loginUser = useAuthStore(state => state.loginUser);
	const { orderDetail, customerDetail, lastQuotation, quotationList, fetchOrderDetail, setOrderPropValue, appendOrderComment } = useOrderStore();

	const navigate = useNavigate();
	const orderLabelOptions = copy(OrderLabelDefaultOptions);
	orderLabelOptions.unshift({ value: 0, label: "未设置", disabled: true });

	const orderStatusOptions = copy(OrderStatusDefaultOptions);

	useEffect(() => {
		if (currentOrder) {
			setLoading(true);
			fetchOrderDetail(currentOrder)
				.finally(() => setLoading(false))
				.catch(reason => {
					notification.error({
						message: "查询出错",
						description: reason.message,
						placement: "top",
						duration: 60,
					});
				});
		}
	}, [currentOrder]);

	let regularText = "";
	if (orderDetail.buytime > 0) regularText = "(R" + orderDetail.buytime + ")";

	const { openOrderContactConversation } = useConversationNewItem();
	const [newChatModalVisible, setNewChatModalVisible] = useState(false);
	const [newChatFormValues, setNewChatFormValues] = useState({});
	const handleNewChat = async values => {
		const newContact = { wa_id: values.wa_id };
		openOrderContactConversation(newContact.wa_id);
		setNewChatModalVisible(false);
	};

	if (currentOrder) {
		return (
			<div className="divide-x-0 divide-y divide-dashed divide-gray-300">
				<Spin spinning={loading}>
					<Card
						className="p-2 "
						bordered={false}
						title={orderDetail.order_no}
						actions={[
							<Select
								key={"orderlabel"}
								size="small"
								style={{
									width: "100%",
								}}
								variant="borderless"
								onSelect={value => {
									setOrderPropValue(currentOrder, "orderlabel", value)
										.then(() => {
											message.success("设置成功");
										})
										.catch(reason => {
											notification.error({
												message: "设置出错",
												description: reason.message,
												placement: "top",
												duration: 60,
											});
										});
								}}
								value={orderDetail.tags}
								options={orderLabelOptions}
							/>,
							<Select
								key={"orderstatus"}
								size="small"
								style={{
									width: "100%",
								}}
								variant="borderless"
								onSelect={value => {
									setOrderPropValue(currentOrder, "orderstatus", value)
										.then(() => {
											message.success("设置成功");
										})
										.catch(reason => {
											notification.error({
												message: "设置出错",
												description: reason.message,
												placement: "top",
												duration: 60,
											});
										});
								}}
								value={orderDetail.states}
								options={orderStatusOptions}
							/>,
						]}>
						<Flex gap={10}>
							<Flex vertical={true} justify="space-between">
								<Typography.Text>
									<UserOutlined className=" pr-1" />
									{customerDetail.name + regularText}
								</Typography.Text>
								<Typography.Text>
									<PhoneOutlined className=" pr-1" />
									<Button
										type="link"
										size={"small"}
										onClick={() => {
											navigate(`/callcenter/call/` + customerDetail.phone);
										}}>
										{customerDetail.phone}
									</Button>
								</Typography.Text>
								<Typography.Text>
									<MailOutlined className=" pr-1" />
									{customerDetail.email}
								</Typography.Text>
								<Typography.Text>
									<WhatsAppOutlined className="pr-1" />
									<Button
										type="link"
										size={"small"}
										onClick={() => {
											setNewChatModalVisible(true);
											setNewChatFormValues(prev => ({ ...prev, phone_number: customerDetail.whatsapp_phone_number, is_current_order: true }));
										}}>
										{customerDetail.whatsapp_phone_number}
									</Button>
								</Typography.Text>
							</Flex>
						</Flex>
					</Card>
					<Divider orientation="left">
						<Typography.Text strong>最新报价</Typography.Text>
					</Divider>
					<Flex vertical={true} className="p-2 ">
						<Conditional
							condition={quotationList.length > 0}
							whenFalse={<Empty description={<span>暂无报价</span>}></Empty>}
							whenTrue={
								<>
									<p className="m-0 py-2 line-clamp-2 ">
										<a target="_blank" href={lastQuotation.letterurl}>
											<LinkOutlined />
											&nbsp;{lastQuotation.lettertitle}
										</a>
									</p>
									<Flex justify={"space-between"}>
										<QuotesHistory dataSource={quotationList} />
									</Flex>
								</>
							}
						/>
					</Flex>

					<Divider orientation="left">
						<Typography.Text strong>表单信息</Typography.Text>
					</Divider>
					<p className="p-2 overflow-auto m-0 break-words whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: orderDetail.order_detail }}></p>
					<Modal
						title="添加备注"
						open={isModalOpen}
						onOk={() => {
							const orderCommnet = orderCommentRef.current.value;
							if (isEmpty(orderCommnet)) {
								message.warning("请输入备注后再提交。");
							} else {
								appendOrderComment(loginUser.userId, currentOrder, orderCommnet)
									.then(() => {
										message.success("添加成功");
										setIsModalOpen(false);
									})
									.catch(reason => {
										notification.error({
											message: "添加出错",
											description: reason.message,
											placement: "top",
											duration: 60,
										});
									});
							}
							orderCommentRef.current.value = "";
						}}
						onCancel={() => {
							setIsModalOpen(false);
						}}>
						<textarea ref={orderCommentRef} className="w-full" rows={4}></textarea>
					</Modal>
					<Button
						size={"small"}
						onClick={() => {
							setIsModalOpen(true);
						}}>
						添加备注
					</Button>
				</Spin>
				<ConversationsNewItem initialValues={newChatFormValues} open={newChatModalVisible} onCreate={handleNewChat} onCancel={() => setNewChatModalVisible(false)} />
			</div>
		);
	} else {
		return (
			<Empty description={<span>暂无相关订单</span>}>
				<ConversationBind currentConversationID={currentConversationID} userId={loginUser.userId} onBoundSuccess={coli_sn => updateCurrentConversation({ coli_sn })} />
			</Empty>
		);
	}
};

export default CustomerProfile;
