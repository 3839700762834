import { fetchJSON, postForm, } from '@/utils/request';
import { API_HOST } from '@/config';

/**
 * 顾问列表
 */
export const fetchSalesAgent = async (q) => {
  const { errcode, result } = await fetchJSON(`https://p9axztuwd7x8a7.mycht.cn/service-Analyse2/GetOperatorInfo`, { q });
  return errcode !== 0 ? [] : result.map((ele) => ({ ...ele, label: ele.cn_name, value: ele.op_id }));
};

/**
 * 绑定钉钉的顾问列表
 */
export const fetchSalesAgentWithDD = async (q) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/opi_with_dingtalk`, { q });
  return errcode !== 0 ? [] : result.map((ele) => ({ ...ele, label: ele.cn_name, value: ele.op_id, key: ele.op_id }));
};

/**
 * 客人列表
 */
export const fetchCustomerList = async (q) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/GetWhatsappidList`, { search: q });
  return errcode !== 0 ? [] : result.map((ele) => ({ ...ele, label: ele.whatsapp_name, value: ele.whatsapp_phone_number }));
};

/**
 * 上传单个文件
 * @returns {object} { errcode, result: { file_url } }
 */
export const postUploadFileItem = async (fileObj, rename) => {
  const formData = new FormData();
  formData.append('file', fileObj, rename);
  const { errcode, result } = await postForm(`${API_HOST}/WAFileUpload`, formData);
  return errcode !== 0 ? {} : result;
};
