
import { groupBy, pick, sortArrayByOrder } from '@/utils/commons';
import { fetchJSON, postJSON, postForm } from '@/utils/request'
import { parseRenderMessageList } from '@/channel/whatsappUtils';
import { API_HOST } from '@/config';
import { isEmpty } from '@/utils/commons';
import dayjs from 'dayjs';

export const fetchTemplates = async () => {
  const data = await fetchJSON(`${API_HOST}/listtemplates`);
  const canUseTemplates = (data?.result?.items || [])
    .filter((_t) => _t.status === 'APPROVED')
    .map((ele) => ({ ...ele, components_origin: ele.components, components: groupBy(ele.components, (_c) => _c.type.toLowerCase()) }));
  const topName = ['free_style_7', 'say_hello_from_trip_advisor', 'free_style_2', 'free_style_1', 'free_style_3', 'free_style_4'];
  const top = sortArrayByOrder( canUseTemplates.filter((_t) => topName.includes(_t.name)), 'name', topName);
  const raw = canUseTemplates.filter((_t) => !topName.includes(_t.name));
  return [...top, ...raw];
};

/**
 *
 * @param {object} params { opisn }
 */
export const fetchConversationsList = async (params) => {
  const { errcode, result: data } = await fetchJSON(`${API_HOST}/getconversations`, params);
  if (errcode !== 0) return [];
  const list = (data || []).map((ele) => ({ ...ele, customer_name: `${ele.whatsapp_name || ''}`.trim(), whatsapp_name: `${ele.whatsapp_name || ''}`.trim() }));
  return list;
};

/**
 *
 * @param {object} params { opisn, whatsappid, colisn }
 * * opisn, colisn : 用于查询
 * * whatsappid: 用于创建会话
 */
export const fetchOrderConversationsList = async (params) => {
  const { errcode, result: data } = await fetchJSON(`${API_HOST}/getorderconversation`, params);
  if (errcode !== 0) return [];
  const list = data.map((ele) => ({ ...ele, customer_name: `${ele.whatsapp_name || ''}`.trim(), whatsapp_name: `${ele.whatsapp_name || ''}`.trim() }));
  return list;
};

export const MESSAGE_PAGE_SIZE = 50;
/**
 *
 * @param {object} params { opisn, whatsappid, lasttime, pagesize }
 */
export const fetchMessages = async (params) => {
  const defaultParams = {
    opisn: '',
    whatsappid: '',
    lasttime: '',
    pagesize: MESSAGE_PAGE_SIZE,
  };
  const { errcode, result } = await fetchJSON(`${API_HOST}/getcusmessages`, {...defaultParams, ...params});
  return errcode !== 0 ? [] : parseRenderMessageList((result || []).reverse());
}

/**
 *
 * @param {object} body { opisn, conversationid }
 */
export const fetchConversationItemClose = async (body) => {
  const { result } = await fetchJSON(`${API_HOST}/closeconversation`, body);
  return result;
};

/**
 * @param {object} body { phone_number, name }
 */
export const postNewConversationItem = async (body) => {
  const formData = new FormData();
  Object.keys(body).forEach(function (key) {
    formData.append(key, body[key]);
  });
  const { errcode, result } = await postForm(`${API_HOST}/new_conversation`, formData);
  if (errcode !== 0) {
    return {};
  }
  const resultItem = result?.[0] || {};
  return {
    ...resultItem,
    customer_name: `${resultItem.whatsapp_name || ''}`.trim(),
    whatsapp_name: `${resultItem.whatsapp_name || ''}`.trim(),
  };
};

/**
 * @param {object} params { opisn, whatsappid }
 */
export const fetchCleanUnreadMsgCount = async (params) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/clean_unread_msg_count`, params);
  return errcode !== 0 ? {} : result;
};

/**
 * 标记未未读
 * @param {object} body conversationItem: { sn, ... }
 */
export const UNREAD_MARK = 999;
export const fetchConversationItemUnread = async (body) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/set_state_unread`, body);
  return errcode !== 0 ? {} : result;
};

/**
 * ------------------------------------------------------------------------------------------------
 * 历史记录
 */
export const CONVERSATION_PAGE_SIZE = 20;
/**
 * @param {object} params { search, from_date, end_date, whatsapp_id, opisn, coli_id, msg_type }
 * @todo msg_type
 */
export const fetchConversationsSearch = async (params) => {
  const { errcode, result: data } = await fetchJSON(`${API_HOST}/conversation_search`, params);
  const list =
    errcode !== 0
      ? []
      : (data || []).map((ele) => ({
          ...ele,
          sn: ele.conversationid,
          customer_name: `${ele.whatsapp_name || ''}`.trim(),
          whatsapp_name: `${ele.whatsapp_name || ''}`.trim(),
          opi_sn: ele.OPI_SN || ele.opi_sn || 0,
          OPI_Name: `${ele.OPI_Name || ele.opi_name || ''}`.trim(),
          opi_name: `${ele.OPI_Name || ele.opi_name || ''}`.trim(),
          dateText: dayjs((ele.lasttime || ele.lasttime)).format('MM-DD HH:mm'),
          matchMsgList: parseRenderMessageList((ele.msglist_AsJOSN || [])), // .reverse()),
          coli_id: '',
        }));
  return list;
};

/**
 *
 * @param {object} params { opisn, whatsappid, lasttime, pagesize, pagedir }
 */
export const fetchMessagesHistory = async (params) => {
  const defaultParams = {
    opisn: '',
    whatsappid: '',
    lasttime: '2024-01-01T00:00:00',
    pagesize: MESSAGE_PAGE_SIZE,
    pagedir: 'next',
  };
  const _params = pick(params, Object.keys(defaultParams));
  if (isEmpty(_params.whatsappid)) {
    return [];
  }
  const { errcode, result } = await fetchJSON(`${API_HOST}/get_item_messages`, {...defaultParams, ..._params});
  const data = errcode !== 0 ? [] : result; // _params.pagedir === 'next' ? result.reverse() : result;
  return parseRenderMessageList(data);
}

/**
 * @param {object} params { opisn, phone_number_from, phone_number_mergeto }
 */
export const fetchConversationMerge = async (params) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/merge_session`, params);
  return errcode !== 0 ? {} : result;
};

/**
 * ------------------------------------------------------------------------------------------------
 * 未分配
 */

export const fetchConversationsUnassigned = async (params) => {
  const { errcode, result: data } = await fetchJSON(`${API_HOST}/unassigned-conversation`, params);
  const list =
    errcode !== 0
      ? []
      : (data || []).map((ele) => ({
          ...ele,
          customer_name: `${ele.whatsapp_name || ''}`.trim(),
          whatsapp_name: `${ele.whatsapp_name || ''}`.trim(),
          opi_sn: ele.OPI_SN || ele.opi_sn || 0,
          OPI_Name: `${ele.OPI_Name || ele.opi_name || ''}`.trim(),
          dateText: dayjs((ele.last_received_time)).format('MM-DD HH:mm'),
        }));
  return list;
};

/**
 * @param {object} params { opi_sn, conversationid }
 */
export const postAssignConversation = async (params) => {
  const { errcode, result } = await fetchJSON(`${API_HOST}/assign_conversation`, params);
  return errcode !== 0 ? {} : result;
}
