import { useRouteError } from "react-router-dom";
import { Card, Typography, Flex, Result, Button } from 'antd'

export default function ErrorPage() {
  const errorResponse = useRouteError();
  console.info('error: ');
  console.dir(errorResponse.message);
  window.$pageSpy.triggerPlugins('onOfflineLog', 'upload');
  return (
    <Result
    status="404"
    title="Sorry, an unexpected error has occurred."
    subTitle={errorResponse?.message || errorResponse.error?.message}
  />
  );
}
