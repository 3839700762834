import { useEffect, useState } from 'react';
import { Layout } from 'antd';
import MessagesHeader from '@/views/Conversations/Online/MessagesHeader';
import MessagesWrapper from '@/views/Conversations/Online/MessagesWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import useConversationStore from '@/stores/ConversationStore';
import { useShallow } from 'zustand/react/shallow';
import InputAssign from './InputAssign';

import { fetchConversationsSearch } from '@/actions/ConversationActions';

const { Content, Header, Footer } = Layout;

function ChatAssign() {
  const navigate = useNavigate();
  const { whatsappid,  } = useParams();

  const [currentConversation, setCurrentConversation] = useConversationStore(useShallow((state) => [state.currentConversation, state.setCurrentConversation]));

  const [conversationid, setConversationid] = useState();
  const [opi, setOpi] = useState({});

  async function refreshConversationList() {
    const _list = await fetchConversationsSearch({ whatsapp_id: whatsappid });
    if (_list.length > 0) {
      setCurrentConversation(_list[0]);
      setConversationid(String(_list[0].conversationid));
      setOpi({ label: _list[0].opi_name, value: String(_list[0].opi_sn) });
    }
  }

  useEffect(() => {
    refreshConversationList();

    return () => {};
  }, [whatsappid]);


  return (
    <>
      <Layout className='h-full chatwindow-wrapper mobilechat-wrapper' style={{ maxHeight: 'calc(100vh - 32px)', height: 'calc(100vh - 32px)', minWidth: '360px' }}>
        <Header className=' px-2 ant-layout-sider-light ant-card h-auto flex flex-col justify-between gap-1 '>
          <InputAssign className={'block py-2'} initialValues={{ conversationid, whatsappid }} {...{ conversationid, opi }} />
          <MessagesHeader />
        </Header>
        <Content className='flex-grow bg-whatsapp-bg relative'>
          <MessagesWrapper updateRead={false} forceGetMessages={true} />
        </Content>
        {/* <Footer className='ant-layout-sider-light p-0 h-40'>
          <InputAssign mobile />
        </Footer> */}
      </Layout>
    </>
  );
}

export default ChatAssign;
