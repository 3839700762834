import ConversationsList from '@/views/Conversations/Online/ConversationsList';

function Conversation() {

  return (
    <div className='chatwindow-wrapper'>
      <ConversationsList mobile={true} />
    </div>
  )
}

export default Conversation
