import { useState } from 'react';
import { App, Button, Form, Input } from 'antd';
import SearchInput from '@/components/SearchInput';
import { fetchSalesAgentWithDD } from '@/actions/CommonActions';
import { postAssignConversation } from '@/actions/ConversationActions';
import useAuthStore from '@/stores/AuthStore';
import {PERM_ASSIGN_NEW_CONVERSATION} from '@/stores/AuthStore';

const InputAssign = ({ initialValues, conversationid, ...props }) => {
  const [userId, username, isPermitted] = useAuthStore((state) => [state.loginUser.userId, state.loginUser.username, state.isPermitted]);
  const actionDisabled = !isPermitted(PERM_ASSIGN_NEW_CONVERSATION);

  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [subLoading, setSubLoading] = useState(false);
  async function handleSubmit(values) {
    const valuesSub = {
      ...values,
      conversationid,
      opi_sn: values.opi_sn.value,
      from_opi_sn: userId, // 谁在操作
    };
    // console.log(valuesSub);
    setSubLoading(true);
    await postAssignConversation(valuesSub);
    setSubLoading(false);
    message.success('分配成功');
  }
  const assignToMe = () => {
    form.setFieldValue('opi_sn', { label: username, value: userId });
  }
  return (
    <>
      <Form layout={'inline'} form={form} initialValues={initialValues} onFinish={handleSubmit} {...props}>
        <Form.Item label='分配至' name='opi_sn' rules={[{ required: true, message: '请选择分配的顾问' }]}>
          <SearchInput placeholder='搜索顾问, 如Coco' fetchOptions={fetchSalesAgentWithDD} allowClear={true} />
        </Form.Item>
        <Form.Item hidden name='conversationid'>
          <Input />
        </Form.Item>
        <div className='flex justify-end gap-4 items-end'>
          {actionDisabled && <div className='text-yellow-400'>无权限操作, 请联系陆燕</div>}
          <Button type='primary' ghost size={'small'} onClick={assignToMe} disabled={actionDisabled || subLoading}>
            分配给我
          </Button>
          <Button type='primary' htmlType='submit' loading={subLoading} disabled={actionDisabled}>
            确认
          </Button>
        </div>
      </Form>
    </>
  );
};
export default InputAssign;
