import React, { PureComponent } from 'react'
import { Result } from 'antd'

// 参考文档：
// https://zh-hans.react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
// https://juejin.cn/post/7168720873006825503
// https://github.com/bvaughn/react-error-boundary/tree/master

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false, info: '' }
  }

  componentDidCatch(error, info) {
    console.error('Sorry, Something went wrong.')
    console.error(error)
    window.$pageSpy.triggerPlugins('onOfflineLog', 'upload');
    this.setState({ hasError: true, info: error.message })
  }

  render() {
    if (this.state.hasError) {
      return <Result
        status='500'
        title='Sorry, Something went wrong.'
        subTitle={this.state.info}
      />
    }
    return this.props.children
  }
}

export default ErrorBoundary
