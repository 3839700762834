import { createContext, useContext, useEffect, useState } from 'react';
import { NavLink, Outlet, Link, useNavigate } from 'react-router-dom';
import { Layout, Button, Flex, theme } from 'antd';
import { LeftOutlined, HomeOutlined } from '@ant-design/icons';

const { Content, Header } = Layout;
const HeaderWrapper = ({ children, ...props }) => {
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      <Layout>
        <Header
          className='header px-2 h-8 border-0 border-b border-neutral-200 border-solid '
          style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: 'white' }}>
          <Flex justify={'space-between'} align={'center'}>
            <Button onClick={() => navigate(-1)} type='link' icon={<LeftOutlined />} />
            <Button onClick={() => navigate('/', { replace: true })} type='link' icon={<HomeOutlined />} />
          </Flex>
        </Header>
        <Content className='' style={{ backgroundColor: colorBgContainer }}>
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};
export default HeaderWrapper;
