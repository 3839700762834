import { create } from 'zustand'
import { fetchJSON } from '@/utils/request'
import { isEmpty, isNotEmpty, } from '@/utils/commons'

export const PERM_MERGE_CONVERSATION = 'merge-conversation'
export const PERM_ASSIGN_NEW_CONVERSATION = 'assign-new-conversation'

const useAuthStore = create((set, get) => ({

  loginUser: {
    userId: -1,
    userIdStr: '-1',
    username: '',
    avatarUrl: '',
    mobile: '',
    email: '',
    openId: '',
    accountList: [],
    permissionList: [],
  },

  loginStatus: 0,

  isPermitted: (perm) => {
    const { loginUser } = get()

    if (perm === PERM_MERGE_CONVERSATION) {
      return ['404', '383', '227'].includes(loginUser.userId)
    }

    if (perm === PERM_ASSIGN_NEW_CONVERSATION) {
      return ['79', '383', '404', '227'].includes(loginUser.userId)
    }
    // 以上是 Hardcode 判断
    // 以下是权限列表从数据库读取后使用的方法
    // return this.permissionList.some((value, key, arry) => {
    //   if (value.indexOf(WILDCARD_TOKEN) > -1) {
    //     return true;
    //   }
    //   if (value === perm) {
    //     return true;
    //   }
    //   return false;
    // });

  },

  login: async (authCode) => {

    const { saveUserSession, setLoginStatus } = get()

    setLoginStatus(200)

    const json = await fetchJSON(`https://p9axztuwd7x8a7.mycht.cn/dingtalk/dingtalkwork/WhatsAppAuth`, { authCode })

    if (json.errcode === 0 && isNotEmpty(json.result.opisn)) {
      set(() => ({
        loginUser: {
          userId: json.result.opisn,
          userIdStr: json.result?.accountlist.map(acc => {
            return acc.OPI_SN
          }).join(','),
          accountName: json.result.opicode,
          username: json.result.nick,
          avatarUrl: json.result.avatarUrl,
          mobile: '+' + json.result.stateCode + '-' + json.result.mobile,
          email: json.result.email,
          openId: json.result.openId,
          accountList: json.result.accountlist,
        }
      }))
      saveUserSession()
      setLoginStatus(302)
    } else {
      setLoginStatus(403)
    }
  },

  setLoginStatus: (code) => {
    set(() => ({
      loginStatus: code
    }))
  },

  logout: () => {
    window.sessionStorage.clear()
    set(() => ({
      loginStatus: 0,
      loginUser: {
        userId: -1,
        userIdStr: '-1',
        username: '',
        avatarUrl: '',
        mobile: '',
        email: '',
        openId: '',
        accountList: [],
        permissionList: [],
      }
    }))

  },

  loadUserSession: () => {
    let sessionData = window.sessionStorage.getItem('GLOBAL_SALES_LOGIN_USER')

    if (import.meta.env.DEV && isEmpty(sessionData)) {
      sessionData = window.localStorage.getItem('GLOBAL_SALES_LOGIN_USER')
    }

    if (sessionData !== null) {
      const sesstionObj = JSON.parse(sessionData);
      set(() => ({
        loginUser: sesstionObj,
      }));
    }
  },

  saveUserSession: () => {
    const { loginUser } = get()
    window.sessionStorage.setItem('GLOBAL_SALES_LOGIN_USER', JSON.stringify(loginUser))
  },

  copyUserSession: () => {
    const sessionData = window.sessionStorage.getItem('GLOBAL_SALES_LOGIN_USER')
    if (sessionData !== null) {
      navigator.clipboard.writeText(sessionData)
    }
  }

}))

export default useAuthStore
