import { Flex, Result, Spin } from 'antd'
import { isNotEmpty } from '@/utils/commons'

function Login() {

  const urlSearch = new URLSearchParams(location.search)  
  const originUrl = urlSearch.get('origin_url')

  if (isNotEmpty(originUrl)) {
    window.location = 'https://login.dingtalk.com/oauth2/auth?redirect_uri=https%3A%2F%2Fsales.mycht.cn%2Fp%2Fdingding%2Fcallback%3Forigin_url%3D' + originUrl + '&response_type=code&client_id=dingwgdx6emlxr3fcrg8&scope=openid&state=global-saels&prompt=consent'
  } else {
    window.location = 'https://login.dingtalk.com/oauth2/auth?redirect_uri=https%3A%2F%2Fsales.mycht.cn%2Fp%2Fdingding%2Fcallback%3Forigin_url%3D&response_type=code&client_id=dingwgdx6emlxr3fcrg8&scope=openid&state=global-saels&prompt=consent'
  }

  return (
    <Flex justify='center' align='center' gap='middle' vertical>
      <Result
        status='success'
        title='欢迎使用'
        subTitle='正在跳转到钉钉登录页面'
        extra={[
          <Spin key='dingding-login' size='small' />
        ]}
      />
    </Flex>
  )
}

export default Login
