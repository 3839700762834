import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const ExpireTimeClock = ({ expireTime }) => {

  const [customerDateTime, setCustomerDateTime] = useState('');
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // .tz('Asia/Shanghai') UTC 时间转换成 Asia/Shanghai 时区时间 GMT+8
      setCustomerDateTime(dayjs(expireTime).add(8, 'hours').fromNow());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [expireTime]);

  useEffect(() => {
    const _ago = customerDateTime.slice(-3) === 'ago';
    setIsExpired(_ago);

    return () => {};
  }, [customerDateTime]);


  return expireTime && !isExpired ? (
    <>
      <Typography.Text className={'text-primary'}>
        <ClockCircleOutlined className='px-1' />
        {isExpired ? 'Expired' : 'Expire'} {customerDateTime}
      </Typography.Text>
    </>
  ) : null;
};
export default ExpireTimeClock;
