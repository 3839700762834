import { useState } from 'react';
import { Popover, Button } from 'antd';
import EmojiPicker from 'emoji-picker-react';

const InputTemplate = ({ mobile, disabled = false, inputEmoji }) => {
  const [openPopup, setOpenPopup] = useState(false);

  const handlePickEmoji = (emojiData) => {
    inputEmoji(emojiData.emoji);
  };

  return (
    <>
      <Popover
        overlayClassName='p-0'
        placement={mobile === undefined ? 'right' : 'top'}
        overlayInnerStyle={{ padding: 0, borderRadius: '8px' }}
        forceRender={true}
        content={<EmojiPicker skinTonesDisabled={true} emojiStyle='native' onEmojiClick={handlePickEmoji} className='chatwindow-wrapper' />}
        title={
          <div className='flex justify-between p-1 '>
            <div>Emoji</div>
            <Button size='small' onClick={() => setOpenPopup(false)}>
              &times;
            </Button>
          </div>
        }
        trigger='click'
        open={openPopup}
        onOpenChange={setOpenPopup}>
        <Button type='text' className=' px-1' size={'middle'} disabled={disabled}>
          😃
        </Button>
      </Popover>
    </>
  );
};
export default InputTemplate;
