import { List, Button } from 'antd';
import { ChatItem } from 'react-chat-elements';

const ConversationsList = ({ conversationsListLoading, handleChatItemClick, selectedConversation, conversationsList, onLoadMore, loadMoreVisible, ...props }) => {
  return (
    <>
      <List
        loading={conversationsListLoading}
        loadMore={
          !conversationsListLoading && loadMoreVisible ? (
            <div className='text-center pt-3 mb-3 h-8 leading-8 '>
              <Button onClick={onLoadMore}>load more</Button>
            </div>
          ) : null
        }
        className='relative'
        itemLayout='vertical'
        dataSource={conversationsList}
        renderItem={(item, index) => (
          <ChatItem
            {...item}
            key={item.conversationid}
            id={item.conversationid}
            letterItem={{ id: item.whatsapp_name || item.whatsapp_phone_number, letter: (item.whatsapp_name || item.whatsapp_phone_number).split(' ')[0] }}
            alt={`${item.whatsapp_name}`}
            title={item.whatsapp_name || item.whatsapp_phone_number}
            subtitle={`${item.OPI_Name || ''} ${item.coli_id || ''}`}
            date={item.lasttime || item.lasttime}
            dateString={item.dateText}
            className={String(item.conversationid) === String(selectedConversation.conversationid) ? '__active text-primary bg-neutral-100' : ''}
            onClick={() => handleChatItemClick(item)}
          />
        )}
      />
    </>
  );
};
export default ConversationsList;
