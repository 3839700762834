const PageSpyLog = () => {
  return (
    <>
      {window.$pageSpy && (
        <a
          className='text-primary'
          onClick={() => {
            window.$pageSpy.triggerPlugins('onOfflineLog', 'download');
            window.$pageSpy.triggerPlugins('onOfflineLog', 'upload');
          }}>
          上传Debug日志 ({window.$pageSpy.address.substring(0, 4)})
        </a>
      )}
    </>
  );
};
export default PageSpyLog;
