import { useCallback, useState, useEffect } from 'react';
import { Divider, Layout, Flex, Image } from 'antd';
import useFormStore from '@/stores/FormStore';
import SearchForm from './Conversations/History/SearchForm';
import ConversationsList from './Conversations/History/ConversationsList';
import MessagesMatchList from './Conversations/History/MessagesMatchList';
import MessagesList from './Conversations/History/MessagesList';
import ImageAlbumPreview from './Conversations/History/ImageAlumPreview';
import { flush, pick } from '@/utils/commons';
import { fetchConversationsSearch, fetchConversationsUnassigned } from '@/actions/ConversationActions';

const { Sider, Content } = Layout;
const Unassign = (props) => {
  const [selectedConversation, setSelectedConversation] = useFormStore((state) => [state.chatHistorySelectChat, state.setChatHistorySelectChat]);

  const [conversationsListLoading, setConversationsListLoading] = useState(false);
  const [conversationsList, setConversationsList] = useState([]);

  useEffect(() => {
    getConversationsList();
    return () => {};
  }, []);

  const getConversationsList = async () => {
    setConversationsListLoading(true);
    const params = {};
    const data = await fetchConversationsSearch(params);
    setConversationsListLoading(false);
    setConversationsList(data);
    if (data.length === 1) {
      setSelectedConversation(data[0]);
    }
  };
  return (
    <>
      <Layout hasSider className='h-screen chathistory-wrapper chatwindow-wrapper' style={{ maxHeight: 'calc(100% - 279px)', height: 'calc(100% - 279px)' }}>
        <Sider width={300} theme={'light'} className='h-full overflow-y-auto overflow-x-hidden' style={{ maxHeight: 'calc(100vh - 279px)', height: 'calc(100vh - 279px)' }}>
          <p className='text-center '>未分配会话</p>
          <ConversationsList {...{ conversationsListLoading, conversationsList, selectedConversation, handleChatItemClick: setSelectedConversation }} />
        </Sider>
        <Content style={{ maxHeight: 'calc(100vh - 279px)', height: 'calc(100vh - 279px)', minWidth: '360px' }}>
          {/* <Flex className='h-full relative'>
        <MessagesMatchList />
        <MessagesList />
      </Flex>
      <ImageAlbumPreview /> */}
        </Content>
      </Layout>
    </>
  );
};
export default Unassign;
