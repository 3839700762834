import { Button, DatePicker, Divider, Form, Input, Select, Table } from 'antd';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

const { RangePicker } = DatePicker

const dataSource = [
  {
    key: '1',
    travelAdvisor: '骆梅玉',
    contactList: 'Giacomo Guilizzoni, Mike, Jerry...',
    important: '5',
    star: '9',
    ing: '3',
  },
  {
    key: '2',
    travelAdvisor: '骆梅玉',
    contactList: 'Giacomo Guilizzoni, Mike, Jerry...',
    important: '5',
    star: '9',
    ing: '3',
  },
  {
    key: '3',
    travelAdvisor: '骆梅玉',
    contactList: 'Giacomo Guilizzoni, Mike, Jerry...',
    important: '5',
    star: '9',
    ing: '3',
  },
  {
    key: '4',
    travelAdvisor: '骆梅玉',
    contactList: 'Giacomo Guilizzoni, Mike, Jerry...',
    important: '5',
    star: '9',
    ing: '3',
  },
  {
    key: '5',
    travelAdvisor: '骆梅玉',
    contactList: 'Giacomo Guilizzoni, Mike, Jerry...',
    important: '5',
    star: '9',
    ing: '3',
  },
  {
    key: '6',
    travelAdvisor: '骆梅玉',
    contactList: 'Giacomo Guilizzoni, Mike, Jerry...',
    important: '5',
    star: '9',
    ing: '3',
  },
  {
    key: '7',
    travelAdvisor: '骆梅玉',
    contactList: 'Giacomo Guilizzoni, Mike, Jerry...',
    important: '5',
    star: '9',
    ing: '3',
  },
];

const SearchForm = memo(function ({ onSubmit }) {
  const [form] = Form.useForm()
  function handleSubmit(values) {
    onSubmit?.(values)
  }
  return (
    <Form
      layout={'inline'}
      form={form}
      initialValues={{ orderLabel: '全部' }}
      onFinish={handleSubmit}
      style={{
        maxWidth: 'none',
      }}
    >
      <Form.Item label='标签' name='orderLabel'>
        <Select
          style={{
            width: 100,
          }}
          options={[
            {
              value: '潜力',
              label: '潜力',
            },
            {
              value: '重点',
              label: '重点',
            },
            {
              value: '休眠',
              label: '休眠',
            }
          ]}
        />
      </Form.Item>
      <Form.Item label='状态' name='orderStatus'>
        <Select
          style={{
            width: 100,
          }}
          options={[
            {
              value: '新订单',
              label: '新订单',
            },
            {
              value: '报价中',
              label: '报价中',
            },
            {
              value: '丢失',
              label: '丢失',
            },
            {
              value: '一催',
              label: '一催',
            },
            {
              value: '二催',
              label: '二催',
            },
            {
              value: '三催',
              label: '三催',
            },
          ]}
        />
      </Form.Item>
      <Form.Item label='订单号' name='orderNumber'>
        <Input placeholder='订单号' allowClear />
      </Form.Item>
      <Form.Item label='出发日期' name='startDate'>
        <RangePicker />
      </Form.Item>
      <Form.Item >
        <Button type='primary' htmlType='submit'>搜索</Button>
      </Form.Item>
    </Form>
  )
})

const SalesTable = function({formValues}) {
  const isMounted = useRef(false)  
  const [salesData, setSalesData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      const randomData = dataSource.map(data => {
        data.travelAdvisor = 'LYJ2024' + Math.floor(Math.random() * (100 - 2 + 1) + 2)
        data.important = Math.floor(Math.random() * (100 - 2 + 1) + 2)
        data.star = Math.floor(Math.random() * (100 - 2 + 1) + 2)
        data.ing = Math.floor(Math.random() * (100 - 2 + 1) + 2)
        return data
      })
      setSalesData([...randomData])
      setLoading(false)
    }, 1000)
    isMounted.current = true
  }, [formValues])
    
  const columns = [
    {
      title: '顾问',
      dataIndex: 'travelAdvisor',
      key: 'travelAdvisor',
    },
    {
      title: '重点订单',
      dataIndex: 'important',
      key: 'important',
    },
    {
      title: '潜力客户',
      dataIndex: 'star',
      key: 'star',
    },
    {
      title: '成型',
      dataIndex: 'ing',
      key: 'ing',
    },
  ]
  return (
    <Table dataSource={salesData} loading={loading} 
    columns={columns}
    pagination={{
      showQuickJumper: true,
      showLessItems: true,
      showSizeChanger: true,
      showTotal: (total) => {return `总数：${total}`}
    }} />
  )
}

function SalesManagement() {

  const [formValues, setFormValues] = useState({})

  const handleSubmit = useCallback((values) => {
    setFormValues({...values})
  }, [])
    
  return (
    <>
      <SearchForm onSubmit={handleSubmit} />
      <Divider plain orientation='left'></Divider>
      <SalesTable formValues={formValues} />
    </>
  )
}

export default SalesManagement
