import { ChatItem } from 'react-chat-elements';
import useFormStore from '@/stores/FormStore';
import { isNotEmpty } from '@/utils/commons';

const MessagesMatchList = ({ ...props }) => {
  const [formValues] = useFormStore((state) => [state.chatHistoryForm]);
  const [selectedConversation] = useFormStore((state) => [state.chatHistorySelectChat]);
  const [selectMatch, setSelectedMatch] = useFormStore((state) => [state.msgHistorySelectMatch, state.setMsgHistorySelectMatch]);

  return (
    <>
      {(selectedConversation.matchMsgList || []).length > 0 && isNotEmpty(formValues.search) && (
        <div className='w-80 overflow-y-auto overflow-x-hidden'>
          <p className='text-center'>
            <mark>{formValues.search}</mark> 的相关记录, 点击定位上下文
          </p>
          {selectedConversation.matchMsgList.map((item) => (
            <ChatItem
              {...item}
              key={item.sn}
              id={item.sn}
              letterItem={{
                id: item.sender === 'me' ? selectedConversation.OPI_Name || item.senderName : item.senderName,
                letter: (item.sender === 'me' ? selectedConversation.OPI_Name || item.senderName : item.senderName).split(' ')[0],
              }}
              alt={`${item.senderName}`}
              title={item.sender === 'me' ? selectedConversation.OPI_Name || item.senderName : item.senderName}
              subtitle={item.originText}
              date={item.msgtime}
              dateString={item.dateText}
              className={String(item.sn) === String(selectMatch?.sn) ? '__active text-primary bg-neutral-100' : ' bg-white'}
              onClick={() => setSelectedMatch(item)}
            />
          ))}
        </div>
      )}
    </>
  );
};
export default MessagesMatchList;
